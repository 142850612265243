import { Controller } from '@hotwired/stimulus'
import { CountUp } from 'countup.js'

export default class extends Controller {
  static classes = ['show', 'deliverable', 'undeliverable', 'risky']
  static targets = ['email', 'score', 'deliverable', 'undeliverable', 'risky']
  static values = { emails: { type: Object } }

  async connect() {
    this.emailsList = Object.keys(this.emailsValue)
    this.counterStarted = false
    const Typed = (await import('typed.js')).default

    this.typist = new Typed(this.emailTarget, {
      backDelay: 4000,
      backSpeed: 15,
      loop: true,
      strings: Object.keys(this.emailsValue),
      typeSpeed: 25,
      onStringTyped: (index, _self) => {
        // index is incremented after every string is typed so we need to subtract 1 to get the index of the most recent email typed
        let indexOfPrev = index - 1
        if (index - 1 < 0) indexOfPrev = this.emailsList.length - 1
        this.score = this.emailsValue[this.emailsList[indexOfPrev]]

        if (this.counterStarted) {
          this.counter.update(this.score)
          this.scoreTarget.classList.remove(
            ...this.deliverableClasses,
            ...this.undeliverableClasses,
            ...this.riskyClasses
          )
        } else {
          this.counter = new CountUp(this.scoreTarget, this.score, {
            duration: 1,
            onCompleteCallback: () => {
              this.#updateEmailScoreColor()
              this.#updateEmailState()
            }
          })

          this.counter.start()
          this.counterStarted = true
        }
      }
    })
  }

  removeAnimation(event) {
    event.target.classList.remove(this.showClass)
  }

  #updateEmailScoreColor() {
    if (this.score >= 80) {
      this.scoreTarget.classList
        .remove(...this.undeliverableClasses, ...this.riskyClasses)
      this.scoreTarget.classList.add(...this.deliverableClasses)
    } else if (this.score >= 1) {
      this.scoreTarget.classList
        .remove(...this.deliverableClasses, ...this.undeliverableClasses)
      this.scoreTarget.classList.add(...this.riskyClasses)
    } else {
      this.scoreTarget.classList
        .remove(...this.deliverableClasses, ...this.riskyClasses)
      this.scoreTarget.classList.add(...this.undeliverableClasses)
    }
  }

  #updateEmailState() {
    if (this.score >= 80) {
      this.deliverableTarget.classList.add(this.showClass)
    } else if (this.score >= 1) {
      this.riskyTarget.classList.add(this.showClass)
    } else {
      this.undeliverableTarget.classList.add(this.showClass)
    }
  }
}
