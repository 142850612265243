import { Controller } from '@hotwired/stimulus'
import { shuffle } from '$javascript/utilities'

export default class extends Controller {
  static classes = ['hide']
  static targets = ['card']
  static values = { count: Number, quotes: Array }

  connect() {
    const randomQuotes = shuffle(this.quotesValue).slice(0, this.countValue)
    this.cardTargets.forEach((card, index) => {
      card.querySelector('q').textContent = `${randomQuotes[index].quote}`
      card.querySelector('cite').textContent = randomQuotes[index].author
      card.classList.remove(...this.hideClasses)
    })
  }
}
